import { CSSProperties, useContext, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { ModalContext } from './ModalContext';

const modalStyles: CSSProperties = {
  position: 'fixed',
  left: 0,
  top: 0,
  display: 'flex',
  width: '100vw',
  height: '100vh',
  background: 'rgba(var(--bg-color-rgb), 0.9)',
  backdropFilter: 'blur(10px)',
  // visibility: 'hidden',
  paddingTop: '4.1666666667vw',
  paddingBottom: '4.1666666667vw',
  // transition: '0.25s ease-in-out',
  // pointerEvents: 'none',
  zIndex: 99,
};

const contentStyles: CSSProperties = {
  display: 'flex',
  maxHeight: '100%',
  justifyContent: 'center',
  // background: 'white',
  // padding: '1rem',
  width: '100%',
  margin: 'auto',
  maxWidth: '120vh',
  // transition: '0.25s ease-in-out',
};

const closeStyles: CSSProperties = {
  position: 'absolute',
  right: '1rem',
  top: '1rem',
  cursor: 'pointer',
  transition: '0.25s ease-in-out',
  background: 'none',
  border: 'none',
  fontSize: '3rem',
  lineHeight: 1,
  fontWeight: 300,
  height: '3rem',
  width: '3rem',
};

const modalVariants = {
  initial: { opacity: 0 },
  open: { opacity: 1 },
  exit: { opacity: 0 },
};
const modalContentVariants = {
  initial: { opacity: 0, y: 10 },
  open: { opacity: 1, y: 0, transition: { delay: 0.5 } },
  exit: { opacity: 0, y: 10 },
};

interface ModalProps {
  id?: string;
  modalOpen: Boolean;
  setModalOpen: Function;
  modalContent?: {
    youtube?: string | null;
    vimeo?: string | null;
    image?: string | null;
  };
  children?: any;
  wrapperClassName?: string;
}

export default function Modal() {
  const { modal, setModal } = useContext(ModalContext);
  const { id, modalOpen, modalContent, children, wrapperClassName } = modal;
  const setModalOpen = (value: boolean) => {
    setModal({ ...modal, modalOpen: value });
  };
  const modalContentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(modalContentRef, () => setModalOpen(false));
  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          key="modal"
          id={id || ''}
          className={`container modal ${modalOpen ? 'active' : ''}`}
          role="dialog"
          style={{
            ...modalStyles,
            // zIndex: modalOpen ? 99 : 0,
            // opacity: modalOpen ? 1 : 0,
            // visibility: modalOpen ? 'visible' : 'hidden',
            // pointerEvents: modalOpen ? 'auto' : 'none',
          }}
          initial="initial"
          animate="open"
          exit="exit"
          variants={modalVariants}
        >
          <motion.div
            className={`modal__content ${wrapperClassName || ''}`}
            ref={modalContentRef}
            style={{
              ...contentStyles,
              // transform: modalOpen ? 'translateY(0)' : 'translateY(10px)',
            }}
            variants={modalContentVariants}
            transition={{ type: 'spring' }}
          >
            {modalContent?.youtube && (
              <div className="modal__youtube iframe-wrapper">
                <iframe
                  width="1920"
                  height="1080"
                  src={`https://www.youtube-nocookie.com/embed/${modalContent.youtube}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)' }}
                />
              </div>
            )}
            {modalContent?.vimeo && (
              <div className="modal__vimeo iframe-wrapper">
                <iframe
                  width="1920"
                  height="1080"
                  src={`https://player.vimeo.com/video/${modalContent.vimeo}?autoplay=1`}
                  title="Vimeo video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)' }}
                />
              </div>
            )}
            {modalContent?.image && (
              <div
                className="modal__image"
                style={{
                  width: '100%',
                  height: 'inherit',
                  textAlign: 'center',
                }}
              >
                <img
                  src={modalContent.image}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
                  }}
                />
              </div>
            )}
            <div
              className="modal__children"
              style={{
                maxWidth: 'calc(100% + 2rem)',
                maxHeight: 'calc(100% + 2rem)',
                overflow: 'auto',
                padding: '1rem', // allow for box shadow
                margin: '-1rem', // allow for box shadow
              }}
            >
              {children}
            </div>
          </motion.div>
          <button
            type="button"
            className="modal__close"
            onClick={() => setModalOpen(false)}
            style={closeStyles}
          >
            &times;
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
